<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
      >
        <template v-slot:item.call_name="{ item }">
          <span class="form-link" @click="open(item)">
            {{ item.call_name }}
          </span>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <!--DATE-->
                    <td
                      v-if="expandItem.value === 'updated_at'"
                      width="150"
                      class="pl-3"
                    >
                      Date
                    </td>
                    <td v-if="expandItem.value === 'updated_at'" colspan="6">
                      {{ item.updated_at }}
                    </td>
                    <!--END DATE-->

                    <!--SEC POLICY NAME-->
                    <td
                      v-if="expandItem.value === 'call_name'"
                      width="150"
                      class="pl-3"
                    >
                      Event ID
                    </td>
                    <td v-if="expandItem.value === 'call_name'" colspan="6">
                      <span class="form-link" @click="open(item)">
                        {{ item.call_name }}
                      </span>
                    </td>
                    <!--END SEC POLICY NAME-->

                    <!--RULENAME-->
                    <td
                      v-if="expandItem.value === 'rulename'"
                      width="150"
                      class="pl-3"
                    >
                      Rulename
                    </td>
                    <td v-if="expandItem.value === 'rulename'" colspan="6">
                      {{ item.rulename }}
                    </td>
                    <!--END RULENAME-->

                    <!--MINER-->
                    <td
                      v-if="expandItem.value === 'miner'"
                      width="150"
                      class="pl-3"
                    >
                      Miner
                    </td>
                    <td v-if="expandItem.value === 'miner'" colspan="6">
                      {{ item.miner }}
                    </td>
                    <!--END MINER-->

                    <!--FIREWALL-->
                    <td
                      v-if="expandItem.value === 'fw'"
                      width="150"
                      class="pl-3"
                    >
                      Firewall
                    </td>
                    <td v-if="expandItem.value === 'fw'" colspan="6">
                      {{ item.fw }}
                    </td>
                    <!--END FIREWALL-->

                    <!--SOURCE SHORT-->
                    <td
                      v-if="expandItem.value === 'src_short'"
                      width="150"
                      class="pl-3"
                    >
                      Source short
                    </td>
                    <td v-if="expandItem.value === 'src_short'" colspan="6">
                      {{ item.src_short }}
                    </td>
                    <!--END SOURCE SHORT-->

                    <!--DESTINATION-->
                    <td
                      v-if="expandItem.value === 'target_short'"
                      width="150"
                      class="pl-3"
                    >
                      Destination
                    </td>
                    <td v-if="expandItem.value === 'target_short'" colspan="6">
                      {{ item.target_short }}
                    </td>
                    <!--END DESTINATION-->

                    <!--THREAT-->
                    <td
                      v-if="expandItem.value === 'threat'"
                      width="150"
                      class="pl-3"
                    >
                      Threat
                    </td>
                    <td v-if="expandItem.value === 'threat'" colspan="6">
                      {{ item.threat }}
                    </td>
                    <!--END THREAT-->

                    <!--DESCRIPTION-->
                    <td
                      v-if="expandItem.value === 'description'"
                      width="150"
                      class="pl-3"
                    >
                      Description
                    </td>
                    <td v-if="expandItem.value === 'description'" colspan="6">
                      {{ item.description ? item.description : "-" }}
                    </td>
                    <!--END DESCRIPTION-->

                    <!--SOURCE-->
                    <td
                      v-if="expandItem.value === 'src'"
                      width="150"
                      class="pl-3"
                    >
                      Source
                    </td>
                    <td v-if="expandItem.value === 'src'" colspan="6">
                      {{ item.src ? item.src : "-" }}
                    </td>
                    <!--END SOURCE-->

                    <!--TARGET-->
                    <td
                      v-if="expandItem.value === 'target'"
                      width="150"
                      class="pl-3"
                    >
                      Target
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'target' &&
                        item.target &&
                        item.target.split(' ').length >= 1
                      "
                      colspan="6"
                    >
                      <v-chip
                        v-for="(ip, index) in item.target.split(' ')"
                        :key="index"
                        x-small
                        color="neutral-4"
                        class="mr-1 text-white"
                      >
                        {{ ip }}
                      </v-chip>
                    </td>
                    <td
                      v-if="
                        expandItem.value === 'target' &&
                        (!item.target || item.target.split(' ').length === 1)
                      "
                      colspan="6"
                    >
                      -
                    </td>
                    <!--END TARGET-->

                    <!--SENT BY-->
                    <td
                      v-if="expandItem.value === 'last_call_user'"
                      width="150"
                      class="pl-3"
                    >
                      Sent by
                    </td>
                    <td
                      v-if="expandItem.value === 'last_call_user'"
                      colspan="6"
                    >
                      {{ item.last_call_user ? item.last_call_user : "-" }}
                    </td>
                    <!--END SENT BY-->

                    <!--INTERNAL ID-->
                    <td
                      v-if="expandItem.value === 'id'"
                      width="150"
                      class="pl-3"
                    >
                      Internal ID
                    </td>
                    <td v-if="expandItem.value === 'id'" colspan="6">
                      {{ item.id ? item.id : "-" }}
                    </td>
                    <!--END INTERNAL ID-->

                    <!--SYSLOG ID-->
                    <td
                      v-if="expandItem.value === 'syslog_id'"
                      width="150"
                      class="pl-3"
                    >
                      Syslog ID
                    </td>
                    <td v-if="expandItem.value === 'syslog_id'" colspan="6">
                      {{ item.syslog_id ? item.syslog_id : "-" }}
                    </td>
                    <!--END SYSLOG ID-->

                    <!--API ID-->
                    <td
                      v-if="expandItem.value === 'api_id'"
                      width="150"
                      class="pl-3"
                    >
                      API ID
                    </td>
                    <td v-if="expandItem.value === 'api_id'" colspan="6">
                      {{ item.api_id ? item.api_id : "-" }}
                    </td>
                    <!--END API ID-->

                    <!--EVENT LINK-->
                    <td
                      v-if="expandItem.value === 'eventlink'"
                      width="150"
                      class="pl-3"
                    >
                      Event Link
                    </td>
                    <td v-if="expandItem.value === 'eventlink'" colspan="6">
                      <a
                        v-if="item.eventlink"
                        :href="item.eventlink"
                        target="_blank"
                        class="form-link"
                        >{{ item.eventlink }}</a
                      >
                      <span v-else>-</span>
                    </td>
                    <!--END EVENT LINK-->
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
  },
  data() {
    return {
      expanded: [],

      page: 1,
      pageTotalCount: 1,
      size: 1,

      sizes: [5, 10, 25, 50, 100, 200],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", { page: newValue, size: this.sizes[this.size] });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (
          newValue !== undefined &&
          newValue !== this.sizes.indexOf(this.tableSize)
        ) {
          this.$emit("change", { page: this.page, size: this.sizes[newValue] });
        }
      },
    },
    expandedHeaders: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue.length === 0) {
          this.expanded = [];
        }
      },
    },
  },
  methods: {
    open(item) {
      this.idAddForm = item.id;
      if (this.$route.name === "successful") {
        this.keywordAddForm = "intSuccessful";
      } else if (this.$route.name === "interconnectorAlertUnsuccessful") {
        this.keywordAddForm = "intUnsuccessful";
      } else if (this.$route.name === "interconnectorAlertReject") {
        this.keywordAddForm = "intReject";
      } else if (this.$route.name === "revoke") {
        this.keywordAddForm = "intRevoke";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
